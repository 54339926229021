<template>
  <div class="text-center">
    <mdb-navbar color="black" dark expand="medium" id="home">
      <mdb-navbar-toggler>
        <mdb-navbar-nav
          class="d-flex flex-center w-100 justify-content-around align-items-center"
        >
          <router-link to="/">
            <img
              :src="logo"
              alt="Sammie Lash Milton Keynes Logo"
              class="img-fluid"
              width="160px"
            />
          </router-link>
        </mdb-navbar-nav>
      </mdb-navbar-toggler>
    </mdb-navbar>
    <p class="h4 py-4">
      Whoops you have gone too far click
      <router-link to="/">here</router-link> to go home or you can view my
      social media. For a booking contact me using the form below.
    </p>
    <Social />
    <mdb-container class="py-5" id="contact">
      <Contact />
    </mdb-container>
  </div>
</template>

<script>
import Social from "@/components/Home/Social.vue";
import Contact from "@/components/Home/Contact.vue";
import {
  mdbNavbar,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbContainer,
} from "mdbvue";
export default {
  name: "Home",
  components: {
    Social,
    Contact,
    mdbNavbar,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbContainer,
  },
  data() {
    return {
      logo: require("../assets/SammieLashMKLogo.jpg"),
    };
  },
};
</script>
