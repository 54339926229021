<template>
  <!-- Footer -->
  <mdb-footer color="black" class="footer-small mt-4">
    <div class="footer-copyright text-center py-3 text-white">
      <mdb-container fluid>
        &copy; 2020 Copyright:
        <a to="/">SammieLash</a>
      </mdb-container>
    </div>
  </mdb-footer>
  <!-- Footer -->
</template>

<script>
import { mdbFooter, mdbContainer } from "mdbvue";
export default {
  name: "FooterPage",
  props: {
    logo: {
      type: String,
      required: true,
    },
  },
  components: {
    mdbFooter,
    mdbContainer,
  },
};
</script>