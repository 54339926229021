<template>
  <div class="fixed-bottom text-right mx-3 mb-2">
    <a href="#home">
      <mdb-btn outline="secondary" class="btn-rounded animated bounce infinite"
        ><mdb-icon icon="arrow-up"></mdb-icon
      ></mdb-btn>
    </a>
  </div>
</template>

<script>
import { mdbBtn, mdbIcon } from "mdbvue";
export default {
  components: {
    mdbBtn,
    mdbIcon,
  },
};
</script>

<style lang="css" scoped>
.btn-rounded {
  border-radius: 10em;
}
</style>