import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  auth
} from "../../firebase";

import Home from '../views/Home.vue'
import Main from '../components/Home/Main.vue'
import Page404 from '../views/404.vue'
import { paths } from '../common/routes'

Vue.use(VueRouter)

const routes = [
  {
    path: paths.ROOT,
    component: Home,
    children: [{
      path: '/',
      component: Main
    }],
  },
  {
    path: paths.ERROR404,
    name: '404',
    component: Page404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const currentUser = auth.currentUser;

  if (requiresAuth && !currentUser) {
    next("/login");
  } else if (requiresAuth && currentUser) {
    next();
  } else if (to.name === "Login" && currentUser) {
    next("/dashboard");
  } else {
    next();
  }
});

function getRoutesList(routes, pre) {
  return routes.reduce((array, route) => {
    const path = `${pre}${route.path}`;

    if (route.path !== '*') {
      array.push(path);
    }

    if (route.children) {
      array.push(...getRoutesList(route.children, `${path}/`));
    }

    return array;
  }, []);
}

getRoutesList(router.options.routes, 'https://sammielash.co.uk');


function getRoutesXML() {
  const list = getRoutesList(router.options.routes, 'https://sammielash.co.uk')
    .map(route => `<url><loc>${route}</loc></url>`)
    .join('\r\n');
  return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
    ${list}
  </urlset>`;
}

getRoutesXML();

export default router
