<template>
  <div id="app">
    <router-view />
    <Footer :logo="logo"></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Nav-footer/Footer.vue";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      logo: require("./assets/SammieLashMKLogo.jpg"),
    };
  },
  metaInfo() {
    return {
      title: "SammieLash",
      titleTemplate: "%s | Milton Keynes",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Eyelash extension artist based in Milton Keynes, specialising in classic and hybrid lashes for any occasion. Taking bookings now. Contact me for your patch test and consultation.",
        },
        {
          property: "og:title",
          content: "SammieLash | Milton Keynes",
        },
        {
          property: "og:description",
          content:
            "Eyelash extension artist based in Milton Keynes, specialising in classic and hybrid lashes for any occasion. Taking bookings now. Contact me for your patch test and consultation.",
        },
        {
          property: "og:url",
          content: "https://sammielash.co.uk/",
        },
        {
          property: "og:image",
          content: this.logo,
        },
        { property: "og:site_name", content: "SammieLash Milton Keynes" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>