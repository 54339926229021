<template>
  <mdb-container fluid class="py-5" id="about">
    <mdb-row class="text-center row flex-center">
      <mdb-col col="12" md="6"
        ><img
          :src="image"
          alt="Samantha Ward Lash artist"
          class="img-fluid"
          style="height: 500px"
      /></mdb-col>
      <mdb-col
        col="12"
        md="6"
        class="order-1 order-md-0 pt-5 p-5"
        v-mdb-animate-on-scroll="'fadeIn'"
        >Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab et autem
        itaque, quis assumenda neque, error fugit iure ea ducimus tempore
        voluptatem at! Ipsum ipsa explicabo odit consectetur corrupti, modi
        dolor nostrum mollitia, in delectus vero impedit itaque unde rem. Magni
        dolore a officiis voluptatem beatae libero quisquam repellendus
        perferendis?</mdb-col
      >
    </mdb-row>
  </mdb-container>
</template>

<script>
import { mdbContainer, mdbCol, mdbRow, mdbAnimateOnScroll } from "mdbvue";

export default {
  data() {
    return {
      image: require("../../assets/Sammie.jpg"),
    };
  },
  components: {
    mdbContainer,
    mdbCol,
    mdbRow,
  },
  directives: {
    mdbAnimateOnScroll,
  },
};
</script>
