import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbvue/lib/mdbvue.css";
import "../mdb/mdbvue/scss/mdb-free.scss";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from "vue-meta";
import { auth } from "../firebase";
// WYSIWYG
import CKEditor from "@ckeditor/ckeditor5-vue";
// Components
import Editor from "./components/UI/Editor.vue";

Vue.use(Meta);
Vue.use(CKEditor);
Vue.component("app-editor", Editor);

Vue.config.productionTip = false


let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      el: "#app",
      router,
      store,
      render: h => h(App)
    });
  }
});

// Check that service workers are supported
if ('serviceWorker' in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js');
  });
}

