<template>
  <div class="social" id="social">
    <div class="elfsight-app-feb345f7-9ed3-4802-921f-d38cac200551"></div>
  </div>
</template>

<script>
export default {
  name: "Social",
};
</script>