<template>
  <div class="home">
    <Nav :logo="logo"></Nav>
    <router-view />
  </div>
</template>

<script>
import Nav from "@/components/Nav-footer/Nav.vue";

export default {
  data() {
    return {
      logo: require("../assets/SammieLashMKLogo.jpg"),
    };
  },
  components: {
    Nav,
  },
  metaInfo() {
    return {
      title: "SammieLash",
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "Eyelash extension artist based in Milton Keynes, specialising in classic and hybrid lashes for any occasion. Taking bookings now. Contact me for your patch test and consultation.",
        },
        {
          property: "og:title",
          content: "SammieLash | Milton Keynes",
        },
        {
          property: "og:description",
          content:
            "Eyelash extension artist based in Milton Keynes, specialising in classic and hybrid lashes for any occasion. Taking bookings now. Contact me for your patch test and consultation.",
        },
        {
          property: "og:url",
          content: "https://sammielash.co.uk/",
        },
        {
          property: "og:image",
          content: this.logo,
        },
        { property: "og:site_name", content: "SammieLash Milton Keynes" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>
