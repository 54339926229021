<template>
  <mdb-container fluid class="black py-5" id="price">
    <section class="text-center my-5 container white-text">
      <h2
        class="h1-responsive font-weight-bold text-center my-5"
        v-mdb-animate-on-scroll="'fadeIn'"
      >
        Prices
      </h2>
      <p
        class="white-text text-center w-responsive mx-auto mb-5"
        v-mdb-animate-on-scroll="'fadeIn'"
      >
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error
        amet numquam iure provident voluptate esse quasi, veritatis totam
        voluptas nostrum quisquam eum porro a pariatur veniam.
      </p>
      <mdb-row
        class="text-dark"
        v-mdb-animate-on-scroll="{ animation: 'fadeInUp', position: -100 }"
      >
        <mdb-col lg="3" md="6" class="mb-lg-0 mb-4">
          <mdb-card>
            <mdb-card-body>
              <h5 class="mb-4">Classic lashes</h5>
              <div class="d-flex justify-content-center">
                <div
                  class="card-circle d-flex justify-content-center align-items-center"
                ></div>
              </div>
              <h2 class="font-weight-bold my-4">£45</h2>
              <p class="grey-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa
                pariatur id nobis accusamus deleniti cumque hic laborum.
              </p>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col lg="3" md="6" class="mb-lg-0 mb-4">
          <mdb-card>
            <mdb-card-body>
              <h5 class="mb-4">Classic In fills</h5>
              <div class="d-flex justify-content-center">
                <div
                  class="card-circle d-flex justify-content-center align-items-center"
                ></div>
              </div>
              <h2 class="font-weight-bold my-4">£25</h2>
              <p class="grey-text">
                At ab ea a molestiae corrupti numquam quo beatae minima ratione
                magni accusantium repellat eveniet quia vitae.
              </p>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col lg="3" md="6" class="mb-lg-0 mb-4">
          <mdb-card>
            <mdb-card-body>
              <h5 class="mb-4">Hybrid lashes</h5>
              <div class="d-flex justify-content-center">
                <div
                  class="card-circle d-flex justify-content-center align-items-center"
                ></div>
              </div>
              <h2 class="font-weight-bold my-4">£50</h2>
              <p class="grey-text">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa
                pariatur id nobis accusamus deleniti cumque hic laborum.
              </p>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
        <mdb-col lg="3" md="6" class="mb-lg-0 mb-4">
          <mdb-card>
            <mdb-card-body>
              <h5 class="mb-4">Hybrid In fills</h5>
              <div class="d-flex justify-content-center">
                <div
                  class="card-circle d-flex justify-content-center align-items-center"
                ></div>
              </div>
              <h2 class="font-weight-bold my-4">£30</h2>
              <p class="grey-text">
                At ab ea a molestiae corrupti numquam quo beatae minima ratione
                magni accusantium repellat eveniet quia vitae.
              </p>
            </mdb-card-body>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
  </mdb-container>
</template>

<script>
import {
  mdbContainer,
  mdbRow,
  mdbCol,
  mdbCard,
  mdbCardBody,
  mdbAnimateOnScroll,
} from "mdbvue";
export default {
  name: "EcommercePage",
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
  },
  directives: {
    mdbAnimateOnScroll,
  },
  data() {
    return {};
  },
};
</script>