<template>
  <mdb-navbar color="black" dark expand="medium" id="home">
    <mdb-navbar-brand class="m-0">
      <img
        :src="logo"
        alt="SammieLash Milton Keynes Logo"
        class="img-fluid d-md-none"
        width="100px"
      />
    </mdb-navbar-brand>

    <mdb-navbar-toggler>
      <mdb-navbar-nav
        class="d-flex flex-center w-100 justify-content-around align-items-center"
      >
        <mdb-nav-item href="#about" active>About</mdb-nav-item>
        <mdb-nav-item href="#price" active>Price List</mdb-nav-item>

        <router-link to="/" class="d-none d-md-block">
          <img
            :src="logo"
            alt="Sammie Lash Milton Keynes Logo"
            class="img-fluid"
            width="160px"
          />
        </router-link>

        <mdb-nav-item href="#social">Social</mdb-nav-item>
        <mdb-nav-item href="#contact">Contact</mdb-nav-item>
      </mdb-navbar-nav>
    </mdb-navbar-toggler>
  </mdb-navbar>
</template>

<script>
import {
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
} from "mdbvue";
export default {
  name: "NavbarPage",
  props: {
    logo: {
      type: String,
      required: true,
    },
  },
  components: {
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
  },
};
</script>
