<template>
  <div class="main">
    <Headline />
    <About />
    <PriceList />
    <Social />
    <mdb-container class="py-5" id="contact">
      <Contact />
    </mdb-container>
    <HomeBtn />
  </div>
</template>

<script>
import About from "@/components/Home/About.vue";
import Headline from "@/components/Home/Headline.vue";
import HomeBtn from "@/components/Home/HomeBtn.vue";
import PriceList from "@/components/Home/PriceList.vue";
import Contact from "@/components/Home/Contact.vue";
import Social from "@/components/Home/Social.vue";
import { mdbContainer } from "mdbvue";
export default {
  data() {
    return {
      logo: require("../../assets/SammieLashMKLogo.jpg"),
    };
  },
  components: {
    Social,
    Contact,
    PriceList,
    HomeBtn,
    Headline,
    About,
    mdbContainer,
  },
};
</script>