<template>
  <div class="classic-form-page">
    <div class="view" :style="{ backgroundImage: 'url(' + image + ')' }">
      <mdb-mask
        class="d-flex gradient justify-content-center align-items-center"
      >
        <mdb-container>
          <mdb-row class="flex-center">
            <mdb-col
              md="6"
              xl="5"
              class="white-text text-center text-md-left mb-5"
            >
              <h1 class="h1-responsive font-weight-bold animated fadeInLeft">
                SammieLash
              </h1>
              <h3 class="pt-2 animated fadeInLeft">Milton Keynes</h3>
              <hr class="hr-light animated fadeInLeft" />
              <h6 class="mb-4 animated fadeInLeft">
                Taking bookings now so contact me for any enquiries using social
                media or the contact form. I look forward to hearing from you.
              </h6>
              <hr class="hr-light animated fadeInLeft" />
              <ul
                class="list-inline text-center list-unstyled animated fadeInLeft"
              >
                <li class="list-inline-item">
                  <a
                    class="p-2 fa-lg w-ic"
                    href="https://www.facebook.com/pg/sammielashmk/posts/"
                    target="_blank"
                  >
                    <mdb-icon fab icon="facebook" size="lg" color="secondary" />
                  </a>
                </li>
                <li class="list-inline-item">
                  <a
                    class="p-2 fa-lg w-ic"
                    href="https://www.instagram.com/SammieLashMK/"
                    target="_blank"
                  >
                    <mdb-icon
                      fab
                      icon="instagram"
                      color="secondary"
                      size="lg"
                    />
                  </a>
                </li>
              </ul>
            </mdb-col>
            <mdb-col md="6" xl="5" class="mb-4 animated fadeInRight">
              <Contact type="headline"></Contact>
            </mdb-col>
          </mdb-row>
        </mdb-container>
      </mdb-mask>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Home/Contact.vue";
import { mdbContainer, mdbRow, mdbCol, mdbMask, mdbIcon } from "mdbvue";
export default {
  name: "Headline",
  data() {
    return {
      image: require("../../assets/MagLamp.jpg"),
    };
  },
  components: {
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbMask,
    mdbIcon,
    Contact,
  },
};
</script>

<style>
.classic-form-page .view {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: calc(100vh);
}

.classic-form-page .gradient {
  background: -webkit-linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7),
    rgb(0, 0, 0) 100%
  );
  background: linear-gradient(
    300deg,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.164) 100%
  );
}

.classic-form-page .card {
  background-color: rgba(0, 0, 0, 0.596);
  color: white !important;
}
.classic-form-page .card .form-control {
  color: #ffcdd2;
}

.classic-form-page .card .card-text {
  color: white;
}

.classic-form-page h6 {
  line-height: 1.7;
}

.classic-form-page .navbar {
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.classic-form-page .navbar .md-form {
  margin: 0;
}

.top-nav-collapse {
  background: #424f95 !important;
}

@media (max-width: 768px) {
  .classic-form-page .view {
    height: 1200px;
  }
}

.classic-form-page label {
  color: #fff !important;
}
</style>